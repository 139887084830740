.chat-image-message-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 110px;
  background: rgba(238, 238, 238, 0.6);
  border-radius: 12px;
  overflow: hidden;
  .chat-image-preview {
    max-width: 100%;
    max-height: 320px;
  }
  .chat-image-empty {
    svg{
      width: 64px;
      height: 64px;
    }
  }
  .chat-image-loading {
    border-radius: 12px;
    content: '';
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(135, 135, 135, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    svg {
      width: 48px;
      height: 48px;
    }
    .ant-btn {
      border: 0;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .chat-image-error {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
    border-radius: 50%;
    svg {
      width: 16px;
      height: 16px;
    }
    .chat-image-tip {
      visibility: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      color: #E02828;
    }
  }
  &:hover{
    .chat-image-tip {
      visibility: visible;
    }
  }
}
